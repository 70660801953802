import { gql } from "urql";

export const newsEventsCategories = gql(`
  query NewsAndEventsCategories($locale: I18NLocaleCode) {
    categoryNewsAndEvents(locale: $locale, pagination: { pageSize: 30 }) {
      data {
        attributes {
          name
          slug
          description
          locale
        }
      }
    }
  }
`);
