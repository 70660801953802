import { gql } from "urql";

const GET_BLOG_TAGS_QUERY = gql(`
query GetBlogTags($locale: I18NLocaleCode!) {
  blogPostTags(locale: $locale, pagination: { limit: 50, start: 0 }) {
    data {
      id
      attributes {
        name
        slug
        locale
        description
        icon {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
      }
    }
  }
}`);

export default GET_BLOG_TAGS_QUERY;
