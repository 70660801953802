import { gql } from "urql";

export const GET_CASE_STUDY_POST_QUERY = gql(`
query getCaseStudyPost($slug: String!, $locale: I18NLocaleCode!) {
  getCaseStudyPost(slug: $slug, locale: $locale) {
    id
    attributes {
      title
      excerpt
      slug
      post
      publishDate
      metaTitle
      metaDescription
      createdAt
      updatedAt
      publishedAt
      tags {
        data {
          attributes {
            name
            slug
            icon {
              data {
                attributes {
                  name
                  alternativeText
                  caption
                  width
                  height
                  formats
                  hash
                  ext
                  mime
                }
              }
            }
            description
            createdAt
            updatedAt
          }
        }
      }
      category {
        data {
          attributes {
            name
            slug
            description
          }
        }
      }
      author {
        data {
          attributes {
            name
            bio
            email
            twitterHandle
            slug
            location
            createdAt
            updatedAt
            publishedAt
          }
        }
      }
      featuredImage {
        data {
          attributes {
            formats
            alternativeText
          }
        }
      }
    }
  }
}`);
