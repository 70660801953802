import { gql } from "urql";

export const caseStudyCategories = gql(`
  query CaseStudyCategories($locale: I18NLocaleCode) {
    categoryCaseStudies(locale: $locale, pagination: { pageSize: 50 }) {
      data {
        attributes {
          name
          slug
          description
          locale
        }
      }
    }
  }
`);
