import { gql } from "urql";

const GET_ALL_NEWS_AND_EVENTS_QUERY = gql(`
  query AllNewsEvents($locale: I18NLocaleCode, $pagination: PaginationArg, $filters: NewsAndEventFiltersInput) {
    newsAndEvents(locale: $locale, pagination: $pagination, filters: $filters, sort: ["updatedAt:desc", "publishDate:desc", "createdAt:desc"]) {
      data {
        id
        attributes {
          title
          excerpt
          slug
          featuredImage {
            data {
              id
              attributes {
                formats
                alternativeText
              }
            }
          }
          post
          publishDate
          metaTitle
          metaDescription
          author {
            data {
              id
              attributes {
                name
                bio
                image {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          tags {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          isFeatured
          featuredOrder
          createdAt
          updatedAt
          publishedAt
        }
      }
    }
  }
`);

export default GET_ALL_NEWS_AND_EVENTS_QUERY;
