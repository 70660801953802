import Bugsnag from "@bugsnag/js";
import client from "../../urql-client";
import GET_ALL_BLOG_POSTS_QUERY from "gql/queries/getAllBlogPosts";
import GET_ALL_CASE_STUDIES_QUERY from "gql/queries/getAllCaseStudies";
import GET_ALL_PAGES_QUERY from "gql/queries/getAllPages";
import GET_PAGE_DATA from "gql/queries/getPageData";
import GET_LEGAL_PAGE_DATA from "gql/queries/getLegalPageData";
import GET_ALL_LEGAL_PAGES_QUERY from "gql/queries/getAllLegalPages";
import {
  GET_FEATURED_RESOURCES_QUERY,
  caseStudyCategories,
  caseStudySlugs,
  resourcesHomepage,
  GET_BLOG_POST_QUERY,
  GET_NEWS_AND_EVENT_POST_QUERY,
  GET_CASE_STUDY_POST_QUERY,
} from "gql";
import GET_BLOG_TAGS_QUERY from "gql/queries/getBlogTags";
import {
  BlogPostFiltersInput,
  BlogSlugsQuery,
  CaseStudyCategoriesQuery,
  CaseStudyCategoriesQueryVariables,
  CaseStudySlugsQuery,
  GetBlogTagsQuery,
  GetBlogTagsQueryVariables,
  GetFeaturedResourcesQuery,
  GetFeaturedResourcesQueryVariables,
  GetGenericPropsQuery,
  GetResourcesHomepageQuery,
  GetResourcesHomepageQueryVariables,
  NewsAndEventsCategoriesQuery,
  NewsAndEventsCategoriesQueryVariables,
  NewsEventsSlugsQuery,
  PaginationArg,
} from "gql/types/graphql";
import { Category } from "pages/resource-hub";
import { GetPageProps, GetResourcesHomepage } from "./types";
import GET_ALL_NEWS_AND_EVENTS_QUERY from "gql/queries/getAllNewsEvents";
import { Logger } from "services/logger";
import { StrapiLocale, YuLifeLocale, resourceHubLocales } from "@hooks";
import { blogSlugs } from "gql/queries/blogSlugs";
import { newsEventsSlugs } from "gql/queries/newsEventsSlugs";
import { newsEventsCategories } from "gql/queries/newsAndEventsCategories";
import { getStrapiLocale } from "utils/locales/strapi";
import { isValidFilters, isValidPagination } from "./helpers";
import GET_GENERIC_PROPS_QUERY from "gql/queries/getGenericProps";

const QueryMap = {
  blog: {
    query: GET_ALL_BLOG_POSTS_QUERY,
    data: "blogPosts",
  },
  "case-studies": {
    query: GET_ALL_CASE_STUDIES_QUERY,
    data: "caseStudies",
  },
  "news-and-events": {
    query: GET_ALL_NEWS_AND_EVENTS_QUERY,
    data: "newsAndEvents",
  },
};

class StrapiService {
  public client;

  mapStrapiLocaleToNextJsLocale(strapiLocale: string) {
    switch (strapiLocale) {
      case "en-US":
        return "us";
      case "en-ZA":
        return "za";
      case "ja-JP":
        return "jp";
      case "en":
      default:
        return "uk";
    }
  }

  mapNextJsLocaleToStrapiLocale(strapiLocale: string) {
    switch (strapiLocale) {
      case "us":
        return "en-US";
      case "za":
        return "en-ZA";
      case "jp":
        return "ja-JP";
      case "uk":
      default:
        return "en";
    }
  }

  logError(error: any) {
    Logger.error(error);
    Bugsnag.notify(error);
  }

  formatResourceSlugsForStaticPaths(
    records: { slug: string; locale: string }[],
    replicateUkContent = false,
  ) {
    return records.flatMap((record) => {
      if (replicateUkContent && ["en", "uk"].includes(record.locale)) {
        return resourceHubLocales.map((nextJsLocale) => {
          return {
            params: { slug: record.slug },
            locale: nextJsLocale,
          };
        });
      }

      return {
        params: { slug: record.slug },
        locale: this.mapStrapiLocaleToNextJsLocale(record.locale),
      };
    });
  }

  formatResourceCategoriesForStaticPaths(records: Category[]) {
    return records
      .filter((category: Category) => category.slug)
      .map((category: Category) => ({
        params: { category: category.slug },
        locale: this.mapStrapiLocaleToNextJsLocale(category.locale),
      }));
  }

  async getAllPages({ categorySlug }): Promise<any> {
    try {
      const { data, error } = await client
        .query(
          GET_ALL_PAGES_QUERY,
          {
            filters: { category: { slug: { eq: categorySlug } } },
          },
          { requestPolicy: "network-only" },
        )
        .toPromise();

      if (error) {
        throw new Error(`getAllPages: ${JSON.stringify(error)}`);
      }

      return (
        data?.pages.data?.map((page) => ({
          id: page.id,
          ...page.attributes,
        })) || []
      );
    } catch (e) {
      this.logError(e);
      return [];
    }
  }

  async getPage({ slug }: GetPageProps): Promise<any> {
    try {
      const {
        data: {
          pages: { data: pages },
        },
        error,
      } = await client
        .query(GET_PAGE_DATA, { slug }, { requestPolicy: "network-only" })
        .toPromise();

      if (!pages || error) {
        throw new Error(`No data found for slug ${slug}, error: ${error}`);
      }
      return pages.map((page) => ({
        id: page.id,
        ...page.attributes,
      }))[0];
    } catch (e) {
      this.logError(e);
      return {};
    }
  }

  async getAllLegalPages(): Promise<any> {
    try {
      const {
        data: {
          legalPages: { data: pages },
        },
        error,
      } = await client
        .query(GET_ALL_LEGAL_PAGES_QUERY, undefined, {
          requestPolicy: "network-only",
        })
        .toPromise();

      if (error) {
        throw new Error(`getAllLegalPages: ${JSON.stringify(error)}`);
      }

      return pages.map((page) => ({
        id: page.id,
        ...page.attributes,
      }));
    } catch (e) {
      this.logError(e);
      return {};
    }
  }

  async getLegalPage({ slug, locale }: GetPageProps & { locale?: string }): Promise<any> {
    try {
      const {
        data: {
          legalPages: { data: pages },
        },
        error,
      } = await client
        .query(
          GET_LEGAL_PAGE_DATA,
          { slug, locale: this.mapNextJsLocaleToStrapiLocale(locale) },
          { requestPolicy: "network-only" },
        )
        .toPromise();

      if (!pages || error) {
        throw new Error(`No data found for slug ${slug}, error: ${error}`);
      }
      return pages.map((page) => ({
        id: page.id,
        ...page.attributes,
      }))[0];
    } catch (e) {
      this.logError(e);
      return {};
    }
  }

  async getAllPosts(
    parentCategory: any,
    locale: StrapiLocale | "all",
    pagination: PaginationArg,
    filters: BlogPostFiltersInput,
  ): Promise<any> {
    try {
      if (!isValidFilters(filters) || !isValidPagination(pagination)) {
        return []; // fail silently
      }

      const response = await client
        .query(
          QueryMap[parentCategory].query,
          {
            locale,
            pagination,
            filters,
          },
          { requestPolicy: "network-only" },
        )
        .toPromise();

      if (!response || !response.data || !response.data[QueryMap[parentCategory].data]) {
        throw new Error(
          `Debug - Unexpected response in getAllPosts - locale: ${locale}, pagination: ${JSON.stringify(pagination)}, filters: ${JSON.stringify(filters)}`,
        );
      }

      const {
        data: {
          [QueryMap[parentCategory].data]: { data },
        },
        error,
      } = response;

      if (error) {
        throw new Error(`getAllPosts: ${JSON.stringify(error)}`);
      }

      return data.map((page) => ({ id: page.id, ...page.attributes }));
    } catch (e) {
      this.logError(e);
      return [];
    }
  }

  async getBlogTags(locale: StrapiLocale): Promise<Category[]> {
    try {
      const { data, error } = await client
        .query<
          GetBlogTagsQuery,
          GetBlogTagsQueryVariables
        >(GET_BLOG_TAGS_QUERY, { locale }, { requestPolicy: "network-only" })
        .toPromise();

      if (error) {
        throw new Error(`getBlogTags: ${JSON.stringify(error)}`);
      }

      return (
        data?.blogPostTags.data?.map((tag) => ({
          order: tag.id,
          name: tag.attributes.name,
          slug: tag.attributes.slug,
          locale: tag.attributes.locale,
          description: tag.attributes.description,
          iconSrc: tag.attributes.icon?.data?.attributes?.url || null,
          iconAlt: tag.attributes.icon?.data?.attributes?.alternativeText || null,
        })) || []
      );
    } catch (e) {
      this.logError(e);
      return [];
    }
  }

  async getResourceHomePage(locale: StrapiLocale, tag?: string): Promise<GetResourcesHomepage> {
    try {
      const { data, error } = await client
        .query<
          GetResourcesHomepageQuery,
          GetResourcesHomepageQueryVariables
        >(resourcesHomepage, { locale, tag }, { requestPolicy: "network-only" })
        .toPromise();

      if (error) {
        throw new Error(`getResourcesHomepage: ${JSON.stringify(error)}`);
      }

      return data?.getResourcesHomepage;
    } catch (e) {
      this.logError(e);
      return {};
    }
  }

  async getCaseStudiesCategories(locale: StrapiLocale): Promise<Category[]> {
    try {
      const { data, error } = await client
        .query<
          CaseStudyCategoriesQuery,
          CaseStudyCategoriesQueryVariables
        >(caseStudyCategories, { locale }, { requestPolicy: "network-only" })
        .toPromise();

      if (error) {
        throw new Error(`getCaseStudiesCategories: ${JSON.stringify(error)}`);
      }

      const categories = data.categoryCaseStudies.data.map(
        ({ attributes: { name, slug, description, locale } }) => ({
          name,
          slug,
          locale,
          description,
        }),
      );

      return categories;
    } catch (e) {
      this.logError(e);
      return [];
    }
  }

  async getNewsAndEventsCategories(locale: StrapiLocale): Promise<Category[]> {
    try {
      const { data, error } = await client
        .query<
          NewsAndEventsCategoriesQuery,
          NewsAndEventsCategoriesQueryVariables
        >(newsEventsCategories, { locale }, { requestPolicy: "network-only" })
        .toPromise();

      if (error) {
        throw new Error(`getNewsEventsCategories: ${JSON.stringify(error)}`);
      }

      const categories = data.categoryNewsAndEvents.data.map(
        ({ attributes: { name, slug, description, locale } }) => ({
          name,
          slug,
          locale,
          description,
        }),
      );

      // Add pseudo category
      // Not sure if we localise this for JP yet, or ever

      if (locale !== "ja-JP") {
        categories.push({
          name: "All Events",
          slug: "all-events",
          locale,
          description: "Our past and future events",
        });
      }

      return categories;
    } catch (e) {
      this.logError(e);
      return [];
    }
  }

  async getFeaturedResources(
    locale: StrapiLocale,
    tag?: string,
  ): Promise<GetFeaturedResourcesQuery["getFeaturedResources"]> {
    try {
      const { data, error } = await client
        .query<
          GetFeaturedResourcesQuery,
          GetFeaturedResourcesQueryVariables
        >(GET_FEATURED_RESOURCES_QUERY, { locale, tag }, { requestPolicy: "network-only" })
        .toPromise();

      if (error) {
        this.logError(`getFeaturedResources: ${JSON.stringify(error)}`);
      }

      return data?.getFeaturedResources || [];
    } catch (e) {
      this.logError(e);
      return [];
    }
  }

  async getBlogSlugs() {
    try {
      const blogs = await client.query<BlogSlugsQuery>(blogSlugs, {}).toPromise();

      return blogs.data.blogPosts.data.map((record) => {
        return { slug: record.attributes.slug, locale: record.attributes.locale };
      });
    } catch (e) {
      this.logError(`getBlogSlugs: ${JSON.stringify(e)}`);
      return [];
    }
  }

  async getCaseStudiesSlugs() {
    try {
      const caseStudies = await client.query<CaseStudySlugsQuery>(caseStudySlugs, {}).toPromise();

      return caseStudies.data.caseStudies.data.map((record) => {
        return { slug: record.attributes.slug, locale: record.attributes.locale };
      });
    } catch (e) {
      this.logError(`getCaseStudiesSlugs: ${JSON.stringify(e)}`);
      Bugsnag.notify(e);
      return [];
    }
  }

  async getNewsEventsSlugs() {
    try {
      const events = await client.query<NewsEventsSlugsQuery>(newsEventsSlugs, {}).toPromise();

      return events.data.newsAndEvents.data.map((record) => {
        return { slug: record.attributes.slug, locale: record.attributes.locale };
      });
    } catch (e) {
      this.logError(`getNewsEventsSlugs: ${JSON.stringify(e)}`);
      Bugsnag.notify(e);
      return [];
    }
  }

  async getBlogPost(slug: string, locale: StrapiLocale) {
    try {
      const { data, error } = await client
        .query(GET_BLOG_POST_QUERY, { slug, locale }, { requestPolicy: "network-only" })
        .toPromise();

      if (error) {
        Logger.log(`getBlogPost: ${JSON.stringify(error)}`);
      }

      return data?.getBlogPost;
    } catch (e) {
      return {};
    }
  }

  async getCaseStudyPost(slug: string, locale: StrapiLocale) {
    try {
      const { data, error } = await client
        .query(GET_CASE_STUDY_POST_QUERY, { slug, locale }, { requestPolicy: "network-only" })
        .toPromise();

      if (error) {
        Logger.log(`getCaseStudyPost: ${JSON.stringify(error)}`);
      }

      return data?.getCaseStudyPost;
    } catch (e) {
      return {};
    }
  }

  async getNewsAndEventPost(slug: string, locale: StrapiLocale, includedLocales: string[]) {
    try {
      const { data, error } = await client
        .query(
          GET_NEWS_AND_EVENT_POST_QUERY,
          { slug, locale, includedLocales },
          { requestPolicy: "network-only" },
        )
        .toPromise();

      if (error) {
        Logger.log(`getNewsAndEventPost: ${JSON.stringify(error)}`);
      }

      return data?.getNewsAndEventPost;
    } catch (e) {
      return {};
    }
  }

  async getGenericProps(
    locale: YuLifeLocale,
    {
      hasKeyResources = true,
      hasLatestCaseStudy = true,
      resourceType = "blog",
    }: {
      hasKeyResources?: boolean;
      hasLatestCaseStudy?: boolean;
      resourceType?: "blog" | "caseStudy" | "newsAndEvents";
    } = {},
  ) {
    const strapiLocale = getStrapiLocale(locale);

    const { data, error } = await client
      .query<GetGenericPropsQuery>(GET_GENERIC_PROPS_QUERY, {
        locale: strapiLocale,
        options: { hasKeyResources, hasLatestCaseStudy, resourceType },
      })
      .toPromise();

    if (error) {
      this.logError(`getGenericProps: ${JSON.stringify(error)}`);
      return { locale };
    }

    return { locale, ...data.getGenericProps };
  }

  catch(e) {
    this.logError(`getGenericProps: ${JSON.stringify(e)}`);
    Bugsnag.notify(e);
    return {};
  }
}

export const strapiService = new StrapiService();
